import React from 'react';

import { ensureNonNegativeAndForwardTo } from '../../utils/utils.js';

export default function EventSettings({
    min_participants,
    max_participants,
    setMinParticipants,
    setMaxParticipants,
}) {
    return (
        <div className="columns participant-count">
            <div className="column is-6">
                <div className="field">
                    <label>
                        <nobr>Min (Game on):</nobr>
                        <br />
                        <input
                            className="input"
                            type="number"
                            id="min_participants"
                            value={min_participants}
                            onChange={(e) =>
                                ensureNonNegativeAndForwardTo(
                                    e.target.value,
                                    setMinParticipants
                                )
                            }
                            pattern="\d*"
                        />
                    </label>
                </div>
            </div>
            <div className="column is-6">
                <div className="field">
                    <label>
                        Max:
                        <br />
                        <input
                            className="input"
                            type="number"
                            id="max_participants"
                            value={max_participants}
                            onChange={(e) =>
                                ensureNonNegativeAndForwardTo(
                                    e.target.value,
                                    setMaxParticipants
                                )
                            }
                            pattern="\d*"
                        />
                    </label>
                </div>
            </div>
        </div>
    );
}
