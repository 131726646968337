import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        // Check if user is logged in when App loads
        userInfo().then((response) => {
            if (response && response.status === 200) {
                setCurrentUser(response.data.user);
            }
        });
    }, []);

    const signIn = async (email, password) => {
        const server_url = process.env.REACT_APP_ROOT_URL + `/login`;

        try {
            const response = await axios.post(
                server_url,
                { email, password },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            localStorage.setItem('name', response.data.user.name);
            localStorage.setItem('email', response.data.user.email);
            localStorage.setItem('user_id', response.data.user.user_id);

            setCurrentUser(response.data.user);

            return response;
        } catch (err) {
            console.log(err.message);
            if (err.response && err.response.status === 401) {
                return err.response;
            }
            console.log('login error', err.message);
            console.log(err.response);
        }
    };

    const signOut = async () => {
        const server_url = process.env.REACT_APP_ROOT_URL + `/logout`;
        try {
            const response = await axios.post(
                server_url,
                {},
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('user_id');
            setCurrentUser(null);

            return response;
        } catch (err) {
            console.log(err.message);
            if (err.response && err.response.status === 401) {
                return err.response;
            }
            console.log('logout error', err.message);
            console.log(err.response);
        }
    };
    const value = {
        currentUser,
        signIn,
        signOut,
    };

    const userInfo = async () => {
        const server_url = process.env.REACT_APP_ROOT_URL + `/user-info`;

        try {
            const response = await axios.post(
                server_url,
                {
                    email: localStorage.getItem('email'),
                },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response;
        } catch (err) {
            console.log(err.message);
            if (err.response && err.response.status === 401) {
                return err.response;
            }
            console.log(err.response);
        }
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
