//const moment = require('moment');

module.exports = {
    // The tests can send a special signal via localStorage to pretend we're
    // in the corner case where there is no session available for the event.
    // This is prone to create crashes that surface at weird times, so it is
    // important to test. However, this is not a state we can easily get into
    // just by using the Admin UI and we'd have to modify the backend/database
    // to test properly. Or (which is a lot easier) simply by simulating it.
    simulatingNullSession: function (eventId) {
        const flag = localStorage.getItem('simulate_null_session_for_event');
        return flag === eventId;
    },
};
