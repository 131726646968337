import axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';

moment().locale('is');

export const updateSession = async (
    session_id,
    timestamp,
    venue_id,
    admin_alert,
    active
) => {
    const session_url = process.env.REACT_APP_ROOT_URL + '/session_update';
    try {
        const response = await axios.patch(
            session_url,
            {
                session_id: session_id,
                timestamp: timestamp,
                venue_id: venue_id,
                admin_alert: admin_alert,
                active: active,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            {
                withCredentials: true,
            }
        );
        return response.data;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
