import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import moment from 'moment';
import 'moment/locale/is';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../api/AuthContext';
import { createParticipant } from '../api/createParticipant.js';
import { fetchAllParticipantsByOwner } from '../api/fetchParticipants.js';
import ParticipantOverview from '../components/ParticipantOverview.js';
import { showToast, updateToast } from '../utils/toastUtils.js';
import utils from '../utils/utils.js';

moment().locale('is');

function Dashboard() {
    const auth = useAuth();
    const [participants, setParticipants] = useState(null);
    const [showParticipantNewForm, setShowParticipantNewForm] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({ name: '', email: '' });

    const getParticipants = async () => {
        const participantsData = await fetchAllParticipantsByOwner(
            auth.currentUser.user_id
        );
        setParticipants(participantsData);
    };

    const handleAddParticipant = async (e) => {
        e.preventDefault();

        // Reset errors
        setErrors({ name: '', email: '' });

        // Validate fields
        let formIsValid = true;
        if (!name) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Name is required',
            }));
            formIsValid = false;
        }
        if (!email) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Email is required',
            }));
            formIsValid = false;
        }

        if (formIsValid) {
            const toastId = showToast('One moment...', auth.currentUser.email, {
                toastId: 'toastIdCreateParticipant',
            });
            // This needs to be more robust to be able to calculate birthdate safely.
            const ssn = document
                .getElementById('ssn')
                .value.replace('-', '')
                .trim();

            const participantData = {
                name: name,
                nickname: document.getElementById('nickname').value,
                email: email,
                mobile_number: document.getElementById('phone').value,
                ssn: ssn,
                birthdate: ssn ? utils.obtainBirthdateFromSsn(ssn) : null,
                owner_id: auth.currentUser.user_id,
            };

            const res = await createParticipant(participantData);
            if (res && res.status === 201) {
                updateToast(toastId, auth.currentUser.email, {
                    render: 'Participant created',
                    type: 'success',
                    isLoading: false,
                    autoClose: 1500,
                });
                setName('');
                setEmail('');
                document.getElementById('name').value = '';
                document.getElementById('email').value = '';
                document.getElementById('nickname').value = '';
                document.getElementById('phone').value = '';
                document.getElementById('ssn').value = '';

                getParticipants();
            } else {
                updateToast(toastId, auth.currentUser.email, {
                    render: res ? res.data.message : 'No reply from server',
                    type: 'error',
                    autoClose: 2000,
                    isLoading: false,
                });
            }
        }
    };

    useEffect(() => {
        getParticipants();
    }, []);

    const handleAdd = () => {
        setShowParticipantNewForm(!showParticipantNewForm);
    };

    const tooltipOverview =
        'Participants are the people you register to attend your events. ' +
        '"Without participants there, no events, can be." - Yoda.';

    return (
        <>
            <h1 id="participantsPage">
                Participants{' '}
                <Tooltip title={tooltipOverview}>
                    <InfoCircleOutlined />
                </Tooltip>
            </h1>
            <br />
            {window.location.search === '?welcome' && (
                <>
                    <span id="newUserIntro">
                        Before creating your first event you need to create a
                        few participants. <br />
                        <br />
                        Press the 'Add participant' button to get started...
                    </span>
                    <br />
                    <br />
                </>
            )}
            <div className="block">
                {participants && (
                    <ul className="simple-list">
                        {' '}
                        {participants.map((participant) => (
                            <ParticipantOverview
                                key={participant.participant_id}
                                participant={participant}
                                refreshParticipants={getParticipants}
                            />
                        ))}
                    </ul>
                )}
                {showParticipantNewForm && (
                    <div className="inline-form">
                        <div className="field">
                            <label className="label">Name</label>
                            <input
                                className="input is-small"
                                id="name"
                                name="name"
                                type="text"
                                placeholder="name"
                                onChange={(e) => setName(e.target.value)}
                            />
                            {errors.name && (
                                <p className="help is-danger">{errors.name}</p>
                            )}
                        </div>
                        <div className="field">
                            <label className="label">Nickname </label>

                            <input
                                className="input is-small"
                                id="nickname"
                                name="nickname"
                                type="text"
                                placeholder="Nickname"
                            />
                        </div>
                        <div className="field">
                            <label className="label">Email </label>

                            <input
                                className="input is-small"
                                id="email"
                                name="email"
                                type="text"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && (
                                <p className="help is-danger">{errors.email}</p>
                            )}
                        </div>
                        <div className="field">
                            <label className="label">Phone </label>

                            <input
                                className="input is-small"
                                id="phone"
                                name="phone"
                                type="text"
                                placeholder="Phone"
                                size="7"
                            />
                        </div>
                        <div className="field">
                            <label className="label">SSN / kennitala</label>

                            <input
                                className="input is-small"
                                id="ssn"
                                name="ssn"
                                type="text"
                                placeholder="SSN"
                                size="10"
                            />
                        </div>

                        <button
                            id="saveParticipant"
                            className="button is-primary is-small"
                            onClick={handleAddParticipant}
                        >
                            Save
                        </button>
                    </div>
                )}
                <br />
                <button
                    id="addParticipant"
                    className="button is-primary is-small"
                    onClick={handleAdd}
                >
                    + Add participant
                </button>
            </div>
            {window.location.search === '?welcome' && (
                <>
                    <span>
                        Once you have finished adding Participants, click the
                        'Events' link (in the column on the left) to add some
                        events they can attend. <br />
                        <br />
                    </span>
                    <br />
                    <br />
                </>
            )}
        </>
    );
}

export default Dashboard;
