import axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';

moment().locale('is');

export const queryAttendance = async (startDate, endDate, events, limit) => {
    var session_url = process.env.REACT_APP_ROOT_URL + '/attendance-stats';

    try {
        const response = await axios.post(
            session_url,
            {
                start_date: startDate,
                end_date: endDate,
                events: events,
                limit: limit,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            {
                withCredentials: true,
            }
        );

        return response.data.result;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
