import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from './AuthContext'; // Adjust the path as needed

export function ProtectedRoute({ children }) {
    const auth = useAuth();
    // Replace the condition below with your logic to check if the user is authenticated
    if (!auth.currentUser) {
        // Redirect to login page or show an appropriate message
        return <Navigate to="/login" />;
    }

    return children;
}
