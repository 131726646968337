import axios from 'axios';

export const fetchChats = async (session_id) => {
    var session_url =
        process.env.REACT_APP_ROOT_URL + `/chats/?session_id=${session_id}`;

    try {
        const response = await axios.get(session_url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data.chats;
    } catch (err) {
        console.log(err.response);
    }
};

export const sendChatMessage = async (session_id, author, message) => {
    var session_url = process.env.REACT_APP_ROOT_URL + '/chat';
    try {
        const response = await axios.post(session_url, {
            session_id: session_id,
            author: author,
            text: message,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data.chat;
    } catch (err) {
        console.log(err.response);
    }
};
