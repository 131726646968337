import axios from 'axios';

import utils from '../utils/utils.js';

export const fetchVenuesByOwner = async (owner_id) => {
    const server_url = process.env.REACT_APP_ROOT_URL + `/venues_by_owner`;

    try {
        const response = await axios.post(
            server_url,
            {
                owner_id: owner_id,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            {
                withCredentials: true,
            }
        );

        const venues = response.data.venues;
        const orderedVenues = utils.sortByName(venues);
        return orderedVenues;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
