import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { fetchSession } from '../api/fetchSession.js';
import { updateAttendanceEmail } from '../api/updateAttendanceEmail.js';
import currentUserUtils from '../utils/currentUserUtils.js';

function EmailResponse() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const participant = searchParams.get('participant');
    const sessionId = searchParams.get('session');
    const eventId = searchParams.get('event');
    const response = searchParams.get('response');

    const updateAttendance = async (e) => {
        const session = await fetchSession(sessionId);
        const updatedBy = currentUserUtils.getParticipantForOwner(
            session.event.owner_id
        );
        if (updatedBy == null) {
            currentUserUtils.maybeSaveParticipantForOwner(
                session.event.owner_id,
                participant
            );
        }

        await updateAttendanceEmail(
            participant,
            updatedBy,
            sessionId,
            response
        );
        navigate('/session/event/' + eventId);
    };

    updateAttendance();

    return (
        <div className="App">
            {response === 'yes' ? (
                <h1>Thank you for confirming your attendance</h1>
            ) : response === 'no' ? (
                <h1>Thank you for declining the invitation</h1>
            ) : (
                <h1>Invalid response</h1>
            )}
            {participant && sessionId && response && (
                <div>
                    <p>Participant: {participant}</p>
                    <p>Session: {sessionId}</p>
                    <p>Response: {response}</p>
                </div>
            )}
        </div>
    );
}

export default EmailResponse;
