import axios from 'axios';

export const fetchVenue = async (venue_id) => {
    var session_url =
        process.env.REACT_APP_ROOT_URL + `/venue/?venue_id=${venue_id}`;
    try {
        const response = await axios.get(session_url, {
            venue_id: venue_id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data.venue;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
