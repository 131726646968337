import axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';

import utils from '../utils/utils.js';

moment().locale('is');

export const createVenue = async (venue) => {
    const session_url = process.env.REACT_APP_ROOT_URL + '/venue';
    try {
        const response = await axios.post(
            session_url,
            {
                venue: venue,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            {
                withCredentials: true,
            }
        );

        if (response.status === 201) {
            utils.saveLastCreatedUuid(response.data.venue_id);
        }

        return response;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
