import axios from 'axios';

export const fetchSession = async (session_id) => {
    var session_url =
        process.env.REACT_APP_ROOT_URL + `/session?session_id=${session_id}`;

    try {
        const response = await axios.get(session_url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data.session;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};

