import { Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../api/AuthContext';
import { createGathering } from '../api/createGathering.js';
import { deleteGathering } from '../api/deleteGathering.js';
import { fetchGathering } from '../api/fetchGathering.js';
import { fetchGatheringsByOwner } from '../api/fetchGatherings.js';
import { updateGathering } from '../api/updateGathering.js';
import { showToast, updateToast } from '../utils/toastUtils.js';

const { Option } = Select;

export default function Gathering({ gathering_id, setGatheringId }) {
    const auth = useAuth();

    // A list of Gatherings for the combo box.
    const [gatherings, setGatherings] = useState(null);
    // Basic Gathering properties.
    const [gathering_name, setGatheringName] = useState(null);
    const [gathering_image_url, setGatheringImageUrl] = useState(null);
    // Custom Select properties.
    const [open, setOpen] = useState(false);
    // Flow control.
    const [isShowingEditGatheringModal, setIsShowingEditGatheringModal] =
        useState(false);
    const [isShowingDeleteGatheringModal, setIsShowingDeleteGatheringModal] =
        useState(false);

    useEffect(() => {
        getGatherings(auth.currentUser.user_id);
    }, [auth.currentUser.user_id]);

    const toggleEditGatheringModal = async (gathering_id) => {
        if (gathering_id !== '') {
            await getGathering(gathering_id);
        }
        setIsShowingEditGatheringModal(!isShowingEditGatheringModal);
    };

    const toggleDeleteGatheringModal = async (gathering_id) => {
        if (gathering_id !== '') {
            await getGathering(gathering_id);
        }
        setIsShowingDeleteGatheringModal(!isShowingDeleteGatheringModal);
    };

    const getGathering = async (gathering_id) => {
        try {
            const res = await fetchGathering(gathering_id);
            setGatheringName(res.name);
            setGatheringImageUrl(res.image_url !== null ? res.image_url : '');
        } catch (err) {
            console.error(err);
        }
    };

    const getGatherings = async (owner_id) => {
        try {
            const res = await fetchGatheringsByOwner(owner_id);
            setGatherings(res);
        } catch (err) {
            console.error(err);
        }
    };

    const commitGatheringCreate = async () => {
        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdCreateGathering',
        });

        const gathering = {
            owner_id: auth.currentUser.user_id,
            name: gathering_name,
        };

        try {
            let response = await createGathering(gathering);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Group created',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });
            getGatherings(auth.currentUser.user_id);
            gathering_id = response.gathering_id;
        } catch (err) {
            console.error(err);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    const commitGatheringChange = async () => {
        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdUpdateGathering',
        });

        try {
            await updateGathering(
                gathering_id,
                auth.currentUser.user_id,
                gathering_name,
                gathering_image_url
            );
            updateToast(toastId, auth.currentUser.email, {
                render: 'Group updated',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });
            getGatherings(auth.currentUser.user_id);
            toggleEditGatheringModal(gathering_id);
        } catch (err) {
            console.error(err);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    const commitGatheringDelete = async () => {
        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdDeleteGathering',
        });

        try {
            await deleteGathering(gathering_id);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Group deleted',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });
            getGatherings(auth.currentUser.user_id);
            gathering_id = '';
            toggleDeleteGatheringModal(gathering_id);
            toggleEditGatheringModal(gathering_id);
        } catch (err) {
            console.error(err);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    return (
        <>
            <label className="label">Group</label>
            <div className="columns">
                <div className="column custom-dropdown-icon">
                    {/* The combo box showing all the Gatherings for this user. */}
                    <Select
                        id="gathering"
                        popupClassName="gatheringClass"
                        value={gathering_id}
                        style={{
                            width: 300,
                        }}
                        defaultValue=""
                        open={open}
                        onDropdownVisibleChange={(visible) => setOpen(visible)}
                        onChange={setGatheringId}
                        size={'large'}
                        dropdownRender={(menu) => (
                            <div>
                                {menu}
                                <div className="columns">
                                    <div className="column is-two-thirds">
                                        <input
                                            className="input"
                                            id="inputNewGatheringFromCombobox"
                                            onChange={(e) =>
                                                setGatheringName(e.target.value)
                                            }
                                            placeholder="Enter new group name"
                                            type="text"
                                        />
                                    </div>
                                    <div className="column">
                                        <button
                                            id="addNewGatheringFromCombobox"
                                            className="button"
                                            disabled={!gathering_name}
                                            onClick={commitGatheringCreate}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    >
                        <Option value="">Select a group</Option>
                        {gatherings &&
                            gatherings.map((gathering) => (
                                <Option
                                    id={'option-' + gathering.gathering_id}
                                    key={gathering.gathering_id}
                                    value={gathering.gathering_id}
                                >
                                    {gathering.name}
                                </Option>
                            ))}
                    </Select>
                    {
                        /* The edit button (that shows the Edit Gathering modal). */
                        gathering_id && (
                            <div className="dropdown-edit-btn">
                                <img
                                    id={'editGathering-' + gathering_id}
                                    src="/img/icon-edit.svg"
                                    alt="Edit gathering"
                                    onClick={() =>
                                        toggleEditGatheringModal(gathering_id)
                                    }
                                />
                            </div>
                        )
                    }
                </div>
            </div>
            {/* The Edit Gathering modal (edits the currently selected Gathering). */}
            {isShowingEditGatheringModal && (
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">
                                Edit gathering: {gathering_name}
                            </p>
                            <button
                                id="closeEditGathering"
                                className="delete"
                                aria-label="close"
                                onClick={() =>
                                    toggleEditGatheringModal(gathering_id)
                                }
                            ></button>
                        </header>
                        <section className="modal-card-body">
                            <div className="field">
                                <input
                                    className="input"
                                    type="text"
                                    id="gathering_name"
                                    placeholder="Enter gathering name"
                                    value={gathering_name}
                                    onChange={(e) =>
                                        setGatheringName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="field">
                                <input
                                    className="input"
                                    type="text"
                                    id="gathering_image_url"
                                    placeholder="Enter image url"
                                    value={gathering_image_url}
                                    onChange={(e) =>
                                        setGatheringImageUrl(e.target.value)
                                    }
                                />
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                            <button
                                id="buttonConfirmEditGathering"
                                className="button is-success"
                                onClick={commitGatheringChange}
                            >
                                Update
                            </button>
                            <button
                                id="buttonDeleteGathering"
                                className="button is-danger"
                                onClick={() =>
                                    toggleDeleteGatheringModal(gathering_id)
                                }
                            >
                                Delete
                            </button>
                            <button
                                id="cancelEditGathering"
                                className="button"
                                onClick={toggleEditGatheringModal}
                            >
                                Cancel
                            </button>
                        </footer>
                    </div>
                </div>
            )}
            {/* The Delete Gathering modal (confirms deleting currently selected gathering). */}
            {isShowingDeleteGatheringModal && (
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">
                                Delete Gathering: {gathering_name}?
                            </p>
                            <button
                                className="delete"
                                aria-label="close"
                                onClick={() =>
                                    setIsShowingDeleteGatheringModal(false)
                                }
                            ></button>
                        </header>
                        <section className="modal-card-body">
                            Are you sure? This can not be undone!
                        </section>
                        <footer className="modal-card-foot">
                            <button
                                id="confirmDeleteGathering"
                                className="button is-danger"
                                onClick={() =>
                                    commitGatheringDelete(gathering_id)
                                }
                            >
                                Confirm delete
                            </button>
                            <button
                                id="cancelDeleteGathering"
                                className="button"
                                onClick={() =>
                                    setIsShowingDeleteGatheringModal(false)
                                }
                            >
                                Cancel
                            </button>
                        </footer>
                    </div>
                </div>
            )}
        </>
    );
}
