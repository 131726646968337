import React, { useState } from 'react';

import { useAuth } from '../api/AuthContext';
import { deleteParticipant } from '../api/deleteParticipant.js';
import { fetchParticipant } from '../api/fetchParticipant.js';
import { updateParticipant } from '../api/updateParticipant.js';
import ParticipantAttendanceModal from '../components/ParticipantAttendanceModal.js';
import ProfileImage from '../components/ProfileImage.js';
import { showToast, updateToast } from '../utils/toastUtils.js';
import utils from '../utils/utils.js';

export default function ParticipantOverview({
    participant,
    refreshParticipants,
}) {
    const auth = useAuth();

    // Basic Participant properties.
    const [participantName, setParticipantName] = useState('');
    const [participantNickname, setParticipantNickName] = useState('');
    const [participantEmail, setParticipantEmail] = useState('');
    const [participantPhone, setParticipantPhone] = useState('');
    const [participantSsn, setParticipantSsn] = useState('');
    const [participantActive, setParticipantActive] = useState(false);
    const [participantPicture, setParticipantPicture] = useState('');
    // Flow control.
    const [isShowingProfile, setIsShowingProfile] = useState(false);
    const [isShowingEditParticipantModal, setIsShowingEditParticipantModal] =
        useState(false);
    const [isShowingDeleteConfirmation, setIsShowingDeleteConfirmation] =
        useState(false);

    const getParticipant = async () => {
        try {
            const res = await fetchParticipant(participant.participant_id);
            setParticipantName(res.name);
            setParticipantNickName(res.nickname);
            setParticipantEmail(res.email);
            setParticipantPhone(res.mobile_number);
            setParticipantSsn(res.ssn);
            setParticipantActive(res.active);
            setParticipantPicture(res.image_url);
        } catch (err) {
            console.error(err);
        }
    };

    const commitDeleteParticipant = async (participantId) => {
        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdDeleteParticipant',
        });

        try {
            await deleteParticipant(participantId);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Participant deleted',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });
            refreshParticipants();
            setIsShowingDeleteConfirmation(false);
        } catch (err) {
            console.error(err);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    const commitEditParticipant = async () => {
        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdUpdateParticipant',
        });

        try {
            const birthdate = utils.obtainBirthdateFromSsn(participantSsn);

            await updateParticipant(
                participant.participant_id,
                auth.currentUser.user_id,
                participantName,
                participantNickname,
                participantEmail,
                participantPhone,
                participantSsn,
                birthdate || null,
                participantActive
            );
            updateToast(toastId, auth.currentUser.email, {
                render: 'Participant updated',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });
            refreshParticipants();
            setIsShowingEditParticipantModal(!isShowingEditParticipantModal);
        } catch (err) {
            console.error(err);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    const handleActiveChange = () => {
        setParticipantActive((prevActive) => !prevActive);
    };

    const toggleEditParticipantModal = () => {
        getParticipant();
        setIsShowingEditParticipantModal(!isShowingEditParticipantModal);
    };

    const toggleParticipantProfileModal = () => {
        getParticipant();
        setIsShowingProfile(!isShowingProfile);
    };

    return (
        <>
            <li key={'li-' + participant.participant_id}>
                <img
                    id={'editParticipant-' + participant.participant_id}
                    src="/img/icon-edit.svg"
                    alt="Edit participant"
                    className="icon-small"
                    onClick={() => toggleEditParticipantModal()}
                />
                <span onClick={() => setIsShowingDeleteConfirmation(true)}>
                    <img
                        id={'deleteParticipant-' + participant.participant_id}
                        src="/img/icon-delete.svg"
                        alt="Delete participant"
                        className="icon-small"
                    />
                </span>
                {participant.active && (
                    <img
                        src="/img/check.svg"
                        alt="Active"
                        className="icon-medium"
                    />
                )}
                {!participant.active && (
                    <img
                        src="/img/nocheck.svg"
                        alt="Not active"
                        className="icon-small"
                    />
                )}
                <span
                    id={'viewParticipant-' + participant.participant_id}
                    className="name"
                    onClick={() => toggleParticipantProfileModal()}
                >
                    {participant.name}
                </span>
                {participant.nickname && <> / {participant.nickname}</>}
                <span className="light"> ({participant.ssn})</span>
            </li>

            {isShowingDeleteConfirmation && (
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">
                                Delete participant: {participant.name}?
                            </p>
                            <button
                                className="delete"
                                aria-label="close"
                                onClick={() =>
                                    setIsShowingDeleteConfirmation(false)
                                }
                            ></button>
                        </header>
                        <section className="modal-card-body">
                            Are you sure? This can not be undone!
                        </section>
                        <footer className="modal-card-foot">
                            <button
                                id="confirmDeleteParticipant"
                                className="button is-danger"
                                onClick={() =>
                                    commitDeleteParticipant(
                                        participant.participant_id
                                    )
                                }
                            >
                                Confirm delete
                            </button>
                            <button
                                id="cancelDeleteParticipant"
                                className="button"
                                onClick={() =>
                                    setIsShowingDeleteConfirmation(
                                        !isShowingDeleteConfirmation
                                    )
                                }
                            >
                                Cancel
                            </button>
                        </footer>
                    </div>
                </div>
            )}
            {isShowingEditParticipantModal && (
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Edit participant</p>
                            <button
                                id="closeEditParticipant"
                                className="delete"
                                aria-label="close"
                                onClick={() => toggleEditParticipantModal()}
                            ></button>
                        </header>
                        <section className="modal-card-body">
                            <div className="field">
                                <div className="activeSwitch">
                                    <input
                                        id="active"
                                        type="checkbox"
                                        name="active"
                                        checked={participantActive}
                                        onChange={handleActiveChange}
                                        className="switch is-rounded is-success"
                                    />
                                    <label htmlFor="active">Active</label>
                                </div>
                            </div>
                            <div className="field">
                                <input
                                    className="input"
                                    type="text"
                                    id="name"
                                    placeholder="Enter name"
                                    value={participantName}
                                    onChange={(e) =>
                                        setParticipantName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="field">
                                <input
                                    className="input"
                                    type="text"
                                    id="nickname"
                                    placeholder="Enter nick name"
                                    value={participantNickname}
                                    onChange={(e) =>
                                        setParticipantNickName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="field">
                                <input
                                    className="input"
                                    type="email"
                                    id="email"
                                    placeholder="Enter email"
                                    value={participantEmail}
                                    onChange={(e) =>
                                        setParticipantEmail(e.target.value)
                                    }
                                />
                            </div>
                            <div className="field">
                                <input
                                    className="input"
                                    type="tel"
                                    id="phone"
                                    placeholder="Enter mobile phone number"
                                    value={participantPhone}
                                    onChange={(e) =>
                                        setParticipantPhone(e.target.value)
                                    }
                                />
                            </div>
                            <div className="field">
                                <input
                                    className="input"
                                    type="number"
                                    id="ssn"
                                    placeholder="Enter social security number"
                                    value={participantSsn}
                                    onChange={(e) =>
                                        setParticipantSsn(e.target.value)
                                    }
                                />
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                            <button
                                id="confirmEditParticipant"
                                className="button is-success"
                                onClick={commitEditParticipant}
                            >
                                Update
                            </button>
                            <button
                                id="cancelEditParticipant"
                                className="button"
                                onClick={toggleEditParticipantModal}
                            >
                                Cancel
                            </button>
                        </footer>
                    </div>
                </div>
            )}
            {isShowingProfile && (
                <div className="modal is-active">
                    <div className="profile-card">
                        <div className="algn">
                            <div className="card">
                                <div
                                    className="close-btn"
                                    id="closeParticipantOverview"
                                    onClick={toggleParticipantProfileModal}
                                >
                                    <img src="/img/close.png" alt="Close" />
                                </div>
                                <div className="upper-bg">
                                    <ProfileImage
                                        participant={participant}
                                        className="profile-pic"
                                    />
                                </div>
                                <div className="lower-bg">
                                    <div className="text">
                                        <p
                                            id="participantOverviewName"
                                            className="name"
                                        >
                                            {participantName}
                                        </p>
                                        {participantNickname && (
                                            <p
                                                id="participantOverviewNickname"
                                                className="title"
                                            >
                                                {participantNickname}
                                            </p>
                                        )}

                                        <div
                                            id="participantOverviewEmail"
                                            className="title"
                                        >
                                            {participantEmail}
                                        </div>
                                        <div
                                            id="participantOverviewPhone"
                                            className="title"
                                        >
                                            {participantPhone}
                                        </div>
                                        <ParticipantAttendanceModal
                                            key={
                                                'modal-' +
                                                participant.participant_id
                                            }
                                            participant_id={
                                                participant.participant_id
                                            }
                                            participantName={participant.name}
                                            participant_owner_id={
                                                participant.owner_id
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
