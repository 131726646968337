import axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';

moment().locale('is');

export const deleteEvent = async (event_id) => {
    const session_url = process.env.REACT_APP_ROOT_URL + '/event';

    try {
        const response = await axios.delete(session_url, {
            data: {
                event_id: event_id,
            },
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        });
        return response.data;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
