import axios from 'axios';

export const updateParticipant = async (
    participant_id,
    owner_id,
    name,
    nickname,
    email,
    phone,
    ssn,
    birthdate,
    active
) => {
    var session_url = process.env.REACT_APP_ROOT_URL + `/participant/`;
    try {
        const response = await axios.patch(
            session_url,
            {
                participant_id: participant_id,
                owner_id: owner_id,
                name: name,
                nickname: nickname,
                email: email,
                mobile_number: phone,
                ssn: ssn,
                birthdate: birthdate,
                active: active,
                image_url: null,
                notificationsDeclined: 0,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            {
                withCredentials: true,
            }
        );
        return response.data;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
