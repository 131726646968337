import axios from 'axios';

export const fetchParticipant = async (participant_id) => {
    var session_url =
        process.env.REACT_APP_ROOT_URL +
        `/participant/?participant_id=${participant_id}`;
    try {
        const response = await axios.get(session_url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data.participant;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
