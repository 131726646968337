import axios from 'axios';

export const runCron = async (event_id) => {
    var url = process.env.REACT_APP_ROOT_URL + `/run_cron`;

    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response || null;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
    }
};
