import md5 from 'md5';
import React, { useState, useEffect } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

export default function ProfileImage({ participant }) {
    const [userGravatar, setUserGravatar] = useState(null);
    const [loadingGravatar, setLoadingGravatar] = useState(true);

    useEffect(() => {
        const checkForGravatar = async (participant) => {
            try {
                const response = await fetch(
                    `https://www.gravatar.com/avatar/${md5(
                        participant.email.trim().toLowerCase()
                    )}?d=mp&d=404`
                );
                if (response.status === 200) {
                    console.log('Gravatar found for', participant.email);
                    setUserGravatar(
                        `https://www.gravatar.com/avatar/${md5(
                            participant.email.trim().toLowerCase()
                        )}?d=mp`
                    );
                }
            } catch (error) {
                console.error('Error checking for Gravatar', error);
            } finally {
                setLoadingGravatar(false);
            }
        };

        if (participant?.email) {
            checkForGravatar(participant);
        }
    }, [participant]);

    if (participant.image_url) {
        return (
            <Avatar
                src={<img src={participant.image_url} alt={participant.name} />}
            />
        );
    }

    if (loadingGravatar) {
        return <Avatar icon={<UserOutlined />} />;
    }

    if (userGravatar) {
        return (
            <Avatar src={<img src={userGravatar} alt={participant.name} />} />
        );
    }

    const participantInitials = participant.name
        .split(' ')
        .map((n) => n[0])
        .join('');

    return <Avatar>{participantInitials}</Avatar>;
}
