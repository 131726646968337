import React from 'react';
import { NavLink } from 'react-router-dom';

export default function AdminMenu() {
    return (
        <ul className="menu">
            <NavLink to={'/admin'} end>
                <li id="navMenuDashboard">Dashboard</li>
            </NavLink>
            <NavLink to={'/admin/events'}>
                <li id="navMenuEvents">Events</li>
            </NavLink>
            <NavLink to={'/admin/participants/'}>
                <li id="navMenuParticipants">Participants</li>
            </NavLink>
            <NavLink to={'/admin/statistics/'}>
                <li id="navMenuStatistics">Statistics</li>
            </NavLink>
            <NavLink to={'/admin/settings/'}>
                <li id="navMenuSettings">Settings</li>
            </NavLink>
        </ul>
    );
}
