import React from 'react';
import { Link } from 'react-router-dom';

import EventAttendanceProgress from './EventAttendanceProgress';

export default function EventItem({
    name,
    date,
    time,
    venue,
    venue_link,
    short_description,
    event_id,
    session_id,
    minParticipantCount,
    maxParticipantCount,
    type,
    t,
    i,
}) {
    return (
        <div
            className={`columns item ${type ? 'single' : 'repeating'}`}
            {...(i === 0 && { 'data-content': t('Next event') })}
        >
            <div className="column is-one-fourth ">
                <Link to={'/session/event/' + event_id}>
                    <div className="title">
                        {name} {short_description !== '' ? ' - ' : ''}
                        <span>{short_description}</span>
                    </div>
                </Link>
                <div className="details">
                    <div
                        className="date"
                        style={{
                            width: '33%',
                        }}
                    >
                        {date}
                    </div>
                    <div
                        className="time"
                        style={{
                            width: '33%',
                        }}
                    >
                        {time}
                    </div>
                    <div
                        style={{
                            width: '33%',
                        }}
                    >
                        {venue_link && (
                            <Link to={venue_link}>
                                <div className="venue">{venue}</div>
                            </Link>
                        )}
                        {!venue_link && <div className="venue">{venue}</div>}
                    </div>
                </div>
            </div>
            <div className="column is-one-fourth progress">
                <EventAttendanceProgress
                    session_id={session_id}
                    minParticipantCount={minParticipantCount}
                    maxParticipantCount={maxParticipantCount}
                />
            </div>
            <div className="column is-one-fifth link">
                {/* <div className="switch">
                    <span className="switch-left">Mæti</span>
                    <span className="switch-right">Mæti ekki</span>
                    <div className="switch-selected"></div>
                    <div className="slider"></div>
                </div> */}
                <div className="nextNav">
                    <Link
                        id={'view-' + event_id}
                        to={'/session/event/' + event_id}
                    >
                        {t('View')}
                    </Link>
                </div>
            </div>
        </div>
    );
}
