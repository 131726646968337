import axios from 'axios';

import utils from '../utils/utils.js';

export const updateAttendanceEmail = async (
    participantId,
    updatedBy,
    sessionId,
    emailResponse
) => {
    let session_url =
        process.env.REACT_APP_ROOT_URL +
        `/attendance-email-response/?participant_id=${participantId}&session_id=${sessionId}&response=${emailResponse}`;
    if (updatedBy != null) session_url += `&updated_by=${updatedBy}`;
    try {
        const response = await axios.get(session_url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            utils.saveLastCreatedUuid(response.data.attendance);
        }

        return response.data;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
