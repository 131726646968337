import axios from 'axios';

export const signup = async (name, email, password) => {
    const server_url = process.env.REACT_APP_ROOT_URL + `/signup`;

    try {
        const response = await axios.post(server_url, {
            name: name,
            email: email,
            password: password,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        if (err.response.status === 409) {
            return err.response;
        }
        console.log('error', err.message);
        console.log(err.response);
    }
};

export const passwordRequestReset = async (email) => {
    const server_url = process.env.REACT_APP_ROOT_URL + `/password-reset`;

    try {
        const response = await axios.post(server_url, {
            email: email,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log('response', response);
        return response;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
    }
};

export const passwordReset = async (password, token) => {
    const server_url =
        process.env.REACT_APP_ROOT_URL + `/confirm-password-reset`;

    try {
        const response = await axios.post(server_url, {
            password: password,
            token: token,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
    }
};
