import axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';

moment().locale('is');

export const fetchAllEventsByOwner = async (ownerId) => {
    let events_url =
        process.env.REACT_APP_ROOT_URL + '/events_by_owner?owner_id=' + ownerId;

    return handleGetEvents(events_url);
};

export const fetchAllFutureEventsByOwner = async (ownerId) => {
    let events_url =
        process.env.REACT_APP_ROOT_URL +
        '/future_events_by_owner?owner_id=' +
        ownerId;

    return handleGetEvents(events_url);
};

export const fetchAllEventsByGathering = async (gatheringId) => {
    let events_url =
        process.env.REACT_APP_ROOT_URL +
        '/events_by_group?gathering_id=' +
        gatheringId;

    return handleGetEvents(events_url);
};

export const sendAllEventsByEmail = async (email) => {
    let url = process.env.REACT_APP_ROOT_URL + '/events_by_email';

    try {
        const response = await axios.post(
            url,
            { email: email },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        let status = response.data;

        return status;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const fetchAllEventsByParticipants = async (participantIds) => {
    let events_url = process.env.REACT_APP_ROOT_URL + '/events_by_participants';
    try {
        const response = await axios.post(
            events_url,
            { participant_ids: participantIds },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        let events = response.data.events;
        return events;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

const handleGetEvents = async (url, withCredentials) => {
    try {
        const response = await axios.get(url, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        let events = response.data.events;

        return events;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
