import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes, useLocation } from 'react-router-dom';

import { AuthProvider } from './api/AuthContext.js'; // Adjust the path as needed
import { ProtectedRoute } from './api/ProtectedRoute.js';
import NoPage from './pages/404';
import Admin from './pages/Admin';
import EmailResponse from './pages/EmailResponse.js';
import EventList from './pages/EventList.js';
import Events from './pages/Events';
import ForgottenPassword from './pages/ForgottenPassword.js';
import Login from './pages/Login.js';
import ManageEvent from './pages/ManageEvents.js';
import NotificationControl from './pages/NotificationControl.js';
import Participants from './pages/Participants.js';
import PasswordReset from './pages/PasswordReset.js';
import Profile from './pages/Profile.js';
import Session from './pages/Session';
import Settings from './pages/Settings.js';
import Signup from './pages/Signup.js';
import Statistics from './pages/Statistics.js';
import Welcome from './pages/Welcome.js';
import Dashboard from './pages/dashboard.js';

const TRACKING_ID = 'G-GCVMHEZ7VY'; // your Measurement ID

function usePageViews() {
    let location = useLocation();

    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
    }, []);

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname + location.search,
        });
    }, [location]);
}

function App() {
    usePageViews();

    return (
        <AuthProvider>
            <Routes>
                <Route path="/" element={<Events />} />
                <Route path="/group/:id" element={<Events />} />
                <Route path="session/:id" element={<Session />} />
                <Route path="session/event/:id" element={<Session />} />
                <Route path="profile" element={<Profile />} />
                <Route path="signup" element={<Signup />} />
                <Route path="login" element={<Login />} />
                <Route path="email-response" element={<EmailResponse />} />
                <Route
                    path="participant/notifications"
                    element={<NotificationControl />}
                />
                <Route
                    path="password-recovery"
                    element={<ForgottenPassword />}
                />
                <Route
                    path="password-reset/:token"
                    element={<PasswordReset />}
                />

                <Route
                    path="admin"
                    element={
                        <ProtectedRoute>
                            <Admin />
                        </ProtectedRoute>
                    }
                >
                    <Route index element={<Dashboard />} />
                    <Route path="events" element={<EventList />} />
                    <Route path="events/:id" element={<ManageEvent />} />
                    <Route path="session/:id" element={<Session />} />
                    <Route path="participants" element={<Participants />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="statistics" element={<Statistics />} />
                    <Route path="welcome" element={<Welcome />} />
                </Route>

                <Route path="*" element={<NoPage />} />
            </Routes>
        </AuthProvider>
    );
}

export default App;
