import moment from 'moment';
import 'moment/locale/is';
import React from 'react';
import { Link } from 'react-router-dom';

import { simpleToast } from '../utils/toastUtils.js';
import utils from '../utils/utils.js';

moment().locale('is');

export default function PastSessionItemAdmin({ session }) {
    function copyToClipboard() {
        navigator.clipboard.writeText(
            window.location.origin + '/session/event/' + session.event_id
        );
        simpleToast('Copied to clipboard 📋');
    }

    return (
        <li
            className={
                session.event.type === utils.SINGLE_EVENT ? 'single' : ''
            }
        >
            <Link to={'/session/' + session.session_id}>
                {utils.dateMaybeWithYear(session.timestamp)}{' '}
                {moment(session.timestamp).format('HH:mm')} {session.event.name}
                <span
                    id={'pastSessionLink-' + session.session_id}
                    className="light"
                >
                    {session.event.short_description
                        ? ' - ' + session.event.short_description
                        : ''}
                    {session.event.venue}{' '}
                </span>
            </Link>
            -{' '}
            <Link
                to={
                    window.location.origin +
                    '/session/event/' +
                    session.event_id
                }
            >
                <span className="link">link</span>
            </Link>
            {
                <small>
                    <img
                        src="/img/icon-copy.svg"
                        alt="Copy url to clipboard"
                        className="icon-small"
                        onClick={copyToClipboard}
                    />
                </small>
            }
        </li>
    );
}
