import axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';

import utils from '../utils/utils.js';

moment().locale('is');

export const updateAttendance = async (attendance_id, value, updatedBy) => {
    if (!attendance_id) {
        console.warn('attendance_id is not set');
        return;
    }

    const session_url =
        process.env.REACT_APP_ROOT_URL + '/attendance/' + attendance_id;
    try {
        const response = await axios.patch(
            session_url,
            {
                confirmed: value,
                updated_by: updatedBy,
                timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200) {
            utils.saveLastCreatedUuid(response.data.attendance.attendance_id);
        }
        return response.data;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};

export const toggleAllowedAttendance = async (event_id, participant_id) => {
    const session_url =
        process.env.REACT_APP_ROOT_URL + '/allowed_participant/';
    try {
        const response = await axios.patch(
            session_url,
            {
                participant_id: participant_id,
                event_id: event_id,
                timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            {
                withCredentials: true,
            }
        );
        return response;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
