import React, { useEffect, useState } from 'react';

import { passwordRequestReset } from '../api/auth';
import { showToast, updateToast } from '../utils/toastUtils.js';

function ForgottenPassword() {
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [emailFeedback, setEmailFeedback] = useState(
        'Password reset link sent'
    );

    useEffect(() => {
        // Reset emailSent feedback when email changes
        setEmailSent(false);
    }, [email]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Implement actual password reset logic, possibly using an API call

        const toastId = showToast('One moment...', '', {
            toastId: 'toastIdLogin',
        });

        try {
            const res = await passwordRequestReset(email);

            if (res.status === 200 || res.status === 204) {
                updateToast(toastId, '', {
                    render: emailFeedback,
                    type: 'success',
                    isLoading: false,
                    autoClose: 1500,
                });
                setEmailFeedback('Password reset link sent');
                setEmailSent(true);
            } else {
                updateToast(toastId, '', {
                    render: emailFeedback,
                    type: 'error',
                    autoClose: 2000,
                    isLoading: false,
                });
            }
        } catch (err) {
            console.error('Password reset failed', err);
            updateToast(toastId, '', {
                render: 'Password reset failed',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
            setEmailFeedback('Password reset failed');
        }
    };

    return (
        <div className="App">
            <div>
                <div className="signuplogin">
                    <section className="form-section">
                        <div className="form-wrapper">
                            <h1 className="title">Password recovery</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>
                                        <span className="sr-only">
                                            Email address
                                        </span>
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            className="form-input"
                                            required
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                        />
                                    </label>
                                </div>

                                <div className="form-group">
                                    <input
                                        type="submit"
                                        value="Send Reset Link"
                                        className="form-submit"
                                    />
                                </div>
                                {emailSent && (
                                    <div className="form-feedback">
                                        Reset link sent
                                    </div>
                                )}
                                <footer className="form-footer">
                                    <div>
                                        <a href="/login" className="form-link">
                                            {' '}
                                            Go back
                                        </a>
                                    </div>
                                </footer>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
            <div className="footer"></div>
        </div>
    );
}

export default ForgottenPassword;
