import moment from 'moment';
import 'moment/locale/is';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchParticipant } from '../api/fetchParticipant.js';
import currentUserUtils from '../utils/currentUserUtils.js';
import ProfileImage from './ProfileImage.js';

moment().locale('is');

export default function Participation({
    participant,
    onChange,
    currentParticipantId,
    ownerId,
}) {
    let me = false;
    const [showProfile, setShowProfile] = useState(false);

    const [user_name, setParticipantNameUser] = useState('');
    const [user_nickname, setParticipantNickNameUser] = useState('');
    const [user_email, setParticipantEmailUser] = useState('');
    const [user_phone, setParticipantPhoneUser] = useState('');
    const [user_team, setParticipantTeamUser] = useState('');
    const [me_forced, setMeForced] = useState(true);
    //const [user_ssn, setParticipantSSNUser] = useState('');
    //const [user_active, setParticipantActiveUser] = useState('');
    //const [user_id, setParticipantidUser] = useState('');

    const { t } = useTranslation();

    const getParticipant = async (id) => {
        try {
            const res = await fetchParticipant(id);
            setParticipantNameUser(res.name);
            setParticipantNickNameUser(res.nickname);
            setParticipantEmailUser(res.email);
            setParticipantPhoneUser(res.mobile_number);
            setParticipantTeamUser(res.Lið);
            //setParticipantSSNUser(res.ssn);
            //setParticipantActiveUser(res.Virkur);
            //setParticipantidUser(res.id);
        } catch (err) {
            console.error(err);
        }
    };

    // Show delete participant confirmation modal
    const showParticipantProfile = (id) => {
        if (showProfile) {
            setShowProfile(!showProfile);
        } else {
            getParticipant(id);
            setShowProfile(!showProfile);
        }
    };

    const updaterNameToShow = (participant) => {
        if (participant.updater === null) return '';
        if (participant.updated_by === participant.participant_id) return '';
        const index = participant.updater.name.indexOf(' ');
        return (
            ' (' +
            participant.updater.name.substring(0, index) +
            (participant.updater.nickname !== ''
                ? ' / ' + participant.updater.nickname
                : '') +
            ')'
        );
    };

    const removeMe = () => {
        currentUserUtils.deleteParticipantForOwner(ownerId);
        setMeForced(false);
    };

    if (participant.participant_id === currentParticipantId) {
        me = true;
    }
    return (
        <div
            className={`columns playa ${
                participant.confirmed === true ? 'active' : ''
            } ${me === true && me_forced === true ? 'me' : ''}`}
        >
            {me && me_forced === true && (
                <div className="me-icon">
                    {t('Me')}
                    <div className="not-me" onClick={removeMe}>
                        This is not me
                    </div>
                </div>
            )}
            <div className="column is-one-half name">
                {participant.confirmed === true && (
                    <img src="/img/check.svg" alt={t('Is participating')} />
                )}
                {participant.confirmed === false && (
                    <img
                        src="/img/nocheck.svg"
                        alt={t('Is not participating')}
                    />
                )}
                <span
                    id={'name-' + participant.participant_id}
                    className="profile-link"
                    onClick={() =>
                        showParticipantProfile(participant.participant_id)
                    }
                >
                    {participant.name}
                </span>{' '}
                {participant.nickname && <>/ {participant.nickname} </>}
            </div>
            <div className="column is-one-quarter radio">
                <div className="radio-custom">
                    <input
                        type="radio"
                        id={'yes-' + participant.participant_id}
                        name={participant.attendance_id}
                        value="1"
                        checked={participant.confirmed === true}
                        onChange={onChange}
                    />
                    <label
                        htmlFor={'yes-' + participant.participant_id}
                        className="radio-label"
                    >
                        {t('Yes')}
                    </label>
                </div>

                <div className="radio-custom">
                    <input
                        type="radio"
                        id={'no-' + participant.participant_id}
                        name={participant.attendance_id}
                        value="0"
                        checked={participant.confirmed === false}
                        onChange={onChange}
                    />
                    <label
                        htmlFor={'no-' + participant.participant_id}
                        className="radio-label no"
                    >
                        {t('No')}
                    </label>
                </div>
            </div>
            <div
                className="column is-one-quarter date"
                id={'timestamp-' + participant.attendance_id}
            >
                {(participant.confirmed === null
                    ? ''
                    : moment(participant.timestamp).fromNow()) +
                    updaterNameToShow(participant)}
            </div>
            {showProfile && (
                <div className="modal is-active">
                    <div className="profile-card">
                        <div className="algn">
                            <div className="card">
                                <div
                                    className="close-btn"
                                    onClick={showParticipantProfile}
                                >
                                    <img src="/img/close.png" alt="Close" />
                                </div>
                                <div className="upper-bg">
                                    <ProfileImage
                                        participant={participant.participant}
                                        className="profile-pic"
                                    />
                                </div>
                                <div className="lower-bg">
                                    <div className="text">
                                        <p className="name">{user_name}</p>
                                        <p className="title">
                                            {user_nickname} <br /> {user_team}{' '}
                                            <br /> {user_email} <br />{' '}
                                            {user_phone}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
