import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { fetchAllGatheringsForParticipants } from '../api/fetchGatherings.js';

const { Option } = Select;

export default function GroupSelect({ participantIds, gathering_id }) {
    const navigate = useNavigate();

    // Gathering list for combo box.
    const [gatherings, setGatherings] = useState(null);
    // Custom Select properties.
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getAllGatheringsForParticipants(participantIds);
    }, [participantIds]);

    const getAllGatheringsForParticipants = async (participantIds) => {
        const gatherings =
            await fetchAllGatheringsForParticipants(participantIds);
        setGatherings(gatherings);
    };

    const setSelectedGatheringFilter = async (gathering_id) => {
        if (gathering_id !== '') {
            navigate('/group/' + gathering_id);
        } else {
            navigate('/');
        }
        // This forces a re-render. Maybe there's an easier way?
        window.location.reload();
    };

    return (
        <>
            {
                /* The combo box showing all the Groups for this user. Only
                   shown if more than 1 such Group exists. */
                gatherings && gatherings.length > 1 && (
                    <Select
                        id="gathering"
                        value={gathering_id}
                        open={open}
                        style={{
                            width: 130,
                        }}
                        onDropdownVisibleChange={(visible) => setOpen(visible)}
                        onChange={setSelectedGatheringFilter}
                        dropdownRender={(menu) => <div>{menu}</div>}
                    >
                        <Option value="">All</Option>
                        {gatherings &&
                            gatherings.map((gathering) => (
                                <Option
                                    id={'option-' + gathering.gathering_id}
                                    key={gathering.gathering_id}
                                    value={gathering.gathering_id}
                                >
                                    {gathering.name}
                                </Option>
                            ))}
                    </Select>
                )
            }
        </>
    );
}
