import { Dropdown, Popover, Space, Avatar } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { runCron } from '../api/runCron';
import currentUserUtils from '../utils/currentUserUtils.js';
import ProfileImage from './ProfileImage';

export default function ProfileAvatar({ participant, newFunctionality }) {
    const { t, i18n } = useTranslation();

    const [flag, setFlag] = React.useState(null);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setFlag(language === 'is' ? 'flag-icelandic.svg' : 'flag-english.svg');
    };

    const setLanguage = (language) => {
        if (language === 'is') {
            setFlag('flag-icelandic.svg');
        } else {
            setFlag('flag-english.svg');
        }
    };

    const showRunCron = (participant) => {
        return (
            participant.email === 'finnurbreki@gmail.com' ||
            participant.email === 'sindriber@gmail.com' ||
            participant.email === 'sigmars@gmail.com' ||
            participant.email === 'gjonsson@hi.is'
        );
    };

    const logOut = async () => {
        if (newFunctionality) {
            currentUserUtils.deleteParticipantForOwner(participant.owner_id);
        } else {
            // This is wrong (logs out potentially multiple participants), but once
            // we move the avatar to the Session page, that becomes easy to fix.
            localStorage.removeItem('currentParticipantMap');

            // Flow continues on the root page (/) but there won't be any events
            // shown (we don't know which events to show). There probably should
            // be some kind of landing page shown instead, which would also fix
            // the user experience of navigating to the root page without
            // specifying a group code.
        }

        // This forces a re-render. Maybe there's an easier way?
        window.location.reload();
    };

    useEffect(() => {
        setLanguage(i18n.language);
    }, [i18n.language]);

    const items = [
        {
            key: '1',
            label: (
                <div
                    onClick={() => changeLanguage('en')}
                    className="language-menu"
                >
                    English
                    <img src="/img/flag-english.svg" alt="Switch to English" />
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div
                    onClick={() => changeLanguage('is')}
                    className="language-menu"
                >
                    Íslenska
                    <img
                        src="/img/flag-icelandic.svg"
                        alt="Skipta yfir á íslensku"
                    />
                </div>
            ),
        },
    ];

    const popupContent = (
        <div>
            <div className="columns">
                <div className="column">
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {participant.name}
                    </div>
                    <div>{participant.email}</div>
                </div>
                <div className="column language-dropdown">
                    <Dropdown
                        menu={{ items }}
                        placement="bottomRight"
                        className="language-switcher"
                    >
                        <div className="language-switcher">
                            <img
                                width="26"
                                src={'/img/' + flag}
                                alt={t('Language switcher')}
                            />
                        </div>
                    </Dropdown>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    {showRunCron(participant) ? (
                        <button
                            className="button"
                            onClick={runCron}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            Run Cron
                        </button>
                    ) : (
                        ''
                    )}
                </div>
                <div className="column">
                    <button
                        onClick={logOut}
                        id="buttonLogOut"
                        style={{ whiteSpace: 'nowrap' }}
                        className="button"
                    >
                        {t('Log out')}
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <Space wrap>
                <div className="profile-name">{participant.name}</div>
                <Popover content={popupContent} trigger="click">
                    <div className="profile-image">
                        <ProfileImage participant={participant} />
                    </div>
                </Popover>
            </Space>
        </>
    );
}
