import { Button } from 'antd';

import moment from 'moment';
import 'moment/locale/is';
import React, { useEffect } from 'react';
//import VenueList from '../components/VenueList';
//import GroupList from '../components/GatheringList';

moment().locale('is');

function Settings() {
    useEffect(() => {}, []);

    const handleAddGroup = () => {
        console.log('Add Group');
    };
    const handleAddVenue = () => {
        console.log('Add Venue');
    };
    return (
        <>
            <h1>Settings</h1> <br />
            {/*  <h2>Groups</h2>
            <div className="block">
                <GroupList />
                <Button onClick={handleAddGroup}>Add Group</Button>
            </div>
            <h2>Venues</h2>
            <div className="block">
                <VenueList />
                <Button onClick={handleAddVenue}>Add Venue</Button>
            </div> */}
        </>
    );
}

export default Settings;
