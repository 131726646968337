import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { updateNotifications } from '../api/updateNotifications';

function NotificationControl() {
    //const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const participant = searchParams.get('participant');
    const response = searchParams.get('unsubscribe');

    // Get all events.
    const updateNotificationSettings = async (e) => {
        await updateNotifications(participant, response);
        //navigate('/session/event/' + event);
    };

    updateNotificationSettings();

    return (
        <div className="App">
            <div className="container main simple-response">
                {response === 'yes' ? (
                    <h1>
                        Thank you, you have been unsubscribed from all
                        notifications
                    </h1>
                ) : response === 'no' ? (
                    <h1>
                        Thank you, you have subscribed to the event
                        notifications
                    </h1>
                ) : (
                    <h1>Invalid response</h1>
                )}
            </div>
        </div>
    );
}

export default NotificationControl;
