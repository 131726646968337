import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { signup } from '../api/auth.js';
import { showToast, updateToast } from '../utils/toastUtils.js';

function Signup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const toastId = showToast('One moment...', email, {
            toastId: 'toastIdSignup',
        });

        try {
            const res = await signup(
                name,
                email,
                password
                // active,
            );
            if (res.status === 200) {
                updateToast(toastId, email, {
                    render: 'Signup saved',
                    type: 'success',
                    isLoading: false,
                    autoClose: 1500,
                });
                navigate('/admin/?signup=success');
            } else {
                updateToast(toastId, email, {
                    render: res.data.message,
                    type: 'error',
                    autoClose: 2000,
                    isLoading: false,
                });
            }
        } catch (err) {
            updateToast(toastId, email, {
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    return (
        <div className="App">
            <div>
                <div className="signuplogin">
                    <section className="form-section">
                        <div className="form-wrapper">
                            <h1 className="title">Sign up</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group">
                                        <label>
                                            <span className="sr-only">
                                                Name
                                            </span>
                                            <input
                                                id="name"
                                                type="text"
                                                placeholder="Name"
                                                className="form-input"
                                                required
                                                value={name}
                                                onChange={(e) =>
                                                    setName(e.target.value)
                                                }
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>
                                        <span className="sr-only">
                                            Email address
                                        </span>
                                        <input
                                            id="email"
                                            type="email"
                                            placeholder="Email"
                                            className="form-input"
                                            required
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            autoComplete="username"
                                        />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label>
                                        <span className="sr-only">
                                            Password
                                        </span>
                                        <input
                                            id="password"
                                            type="password"
                                            placeholder="Password"
                                            className="form-input"
                                            required
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            autoComplete="new-password"
                                        />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <input
                                        id="submit"
                                        type="submit"
                                        value="Submit"
                                        className="form-submit"
                                    />
                                </div>

                                <footer className="form-footer">
                                    <div>
                                        Already have an account?
                                        <a href="/login" className="form-link">
                                            {' '}
                                            Log in
                                        </a>
                                    </div>
                                </footer>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
            <div className="footer"></div>
        </div>
    );
}

export default Signup;
