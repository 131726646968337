import axios from 'axios';

export const updateEmotion = async (chatId, participantId, emotion) => {
    var emotionUrl = process.env.REACT_APP_ROOT_URL + '/emotion';
    try {
        const response = await axios.post(emotionUrl, {
            chat_id: chatId,
            participant_id: participantId,
            emotion: emotion,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data.emotion;
    } catch (err) {
        console.log(err.response);
    }
};
