import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { passwordReset } from '../api/auth';
import { showToast, updateToast } from '../utils/toastUtils.js';

function PasswordReset() {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [responseMessage, setResponseMessage] = useState('One moment...');
    const token = window.location.pathname.split('/')[2];
    const handleSubmit = async (e) => {
        e.preventDefault();

        const toastId = showToast(responseMessage, '', {
            toastId: 'toastIdLogin',
        });

        try {
            const res = await passwordReset(password, token);
            setResponseMessage(res.data.message);

            if (res.status === 200) {
                updateToast(toastId, '', {
                    render: responseMessage,
                    type: 'success',
                    isLoading: false,
                    autoClose: 1500,
                });
                navigate('/admin');
            } else {
                setResponseMessage(res.data.message);
                updateToast(toastId, '', {
                    render: responseMessage,
                    type: 'error',
                    autoClose: 2000,
                    isLoading: false,
                });
            }
        } catch (err) {
            setResponseMessage('oopps');

            console.log('login failed', err);
            updateToast(toastId, '', {
                render: 'Password reset failed, please try again.',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    return (
        <div className="App">
            <div>
                <div className="signuplogin">
                    <section className="form-section">
                        <div className="form-wrapper">
                            <h1 className="title">Password reset</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>
                                        <span className="sr-only">
                                            Password
                                        </span>
                                        <input
                                            type="password"
                                            placeholder="Password"
                                            className="form-input"
                                            required
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                        />
                                    </label>
                                </div>

                                <div className="form-group">
                                    <input
                                        type="submit"
                                        value="Reset"
                                        className="form-submit"
                                    />
                                </div>

                                <footer className="form-footer">
                                    <div>
                                        <a href="/signup" className="form-link">
                                            {' '}
                                            Go back
                                        </a>
                                    </div>
                                </footer>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
            <div className="footer"></div>
        </div>
    );
}

export default PasswordReset;
