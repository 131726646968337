import React from 'react';

function NoPage() {
    return (
        <div className="App">
            <div className="container main">
                <div className="columns">
                    <div className="column">
                        <h1 className="notfound_404">404 - Page not found</h1>
                    </div>
                </div>
            </div>
            <div className="footer ">
                <div className="not_found"></div>
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NoPage;
