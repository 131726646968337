import axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';

moment().locale('is');

export const updateGathering = async (
    gathering_id,
    owner_id,
    name,
    image_url
) => {
    const session_url = process.env.REACT_APP_ROOT_URL + '/gathering';
    try {
        const response = await axios.patch(
            session_url,
            {
                gathering_id: gathering_id,
                owner_id: owner_id,
                name: name,
                image_url: image_url,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            {
                withCredentials: true,
            }
        );
        return response.data;
    } catch (err) {
        console.error(err.message);
        console.error(err.response);
        throw err;
    }
};
