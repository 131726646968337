import React, { useEffect, useState } from 'react';

import { fetchSessionParticipants } from '../api/fetchParticipants.js';
import utils from '../utils/utils';
import { t } from 'i18next';

export default function EventAttendanceProgress({
    session_id,
    minParticipantCount,
    maxParticipantCount,
}) {
    const [attendance, setAttendance] = useState(0);
    const [attendancePercentage, setAttendancePercentage] = useState(0);
    const [showGameOn, setShowGameOn] = useState(false);

    const getParticipants = async (session_id) => {
        const participantData = await fetchSessionParticipants(session_id);
        setAttendance(utils.getAttendanceCount(participantData));
    };

    useEffect(() => {
        getParticipants(session_id);
    }, [session_id]);

    useEffect(() => {
        setAttendancePercentage(
            utils.getAttendancePercentage(attendance, minParticipantCount)
        );
        if (attendancePercentage >= 100) {
            setShowGameOn(true);
        }
    }, [attendance, minParticipantCount, attendancePercentage]);

    return (
        <div className="progressHolder">
            <div className="progressBar">
                <div
                    className="fillerStyles"
                    style={{
                        width: `${attendancePercentage}%`,
                    }}
                ></div>
            </div>
            {showGameOn && <div className="gameOn">Game on!</div>}
            <span>
                {attendance} {t(' registered')}
            </span>
        </div>
    );
}
