import { Popover, Space, Tooltip } from 'antd';
import { t } from 'i18next';
import React, { useState } from 'react';

import { updateEmotion } from '../api/updateEmotion.js';
import utils from '../utils/utils.js';
import Lottie from 'react-lottie';
import thumbsup from '../assets/thumbsup.json';
import heart from '../assets/heart.json';
import happy from '../assets/happy.json';
import sad from '../assets/sad.json';

// Move to a separate file
const HoverLottie = ({ animationData, height, width, onClick, id }) => {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => setIsHovering(false);
    const options = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
    };

    const handleClick = () => {
        if (onClick) onClick(id);
    };

    return (
        <div
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ display: 'inline-block', cursor: 'pointer' }}
        >
            <Lottie
                options={options}
                height={height}
                width={width}
                isStopped={!isHovering}
                key={isHovering ? 'play' : 'stop'}
            />
        </div>
    );
};

export default function EmoteIconTrigger({
    chatId,
    currentParticipantId,
    refreshChatCallback,
}) {
    const [popupOpen, setPopupOpen] = useState(false);

    const hide = () => {
        setPopupOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        setPopupOpen(newOpen);
    };

    const postEmotion = async (id) => {
        console.log('postEmotion', id);
        let nextEmotionValue = id.slice(-1);

        hide();
        await updateEmotion(chatId, currentParticipantId, nextEmotionValue);
        refreshChatCallback();
    };
    const popupContent = (
        <div className="columns">
            <div className="column">
                <Tooltip placement="top" title={t('Like')}>
                    <HoverLottie
                        animationData={thumbsup}
                        height={24}
                        width={24}
                        onClick={postEmotion}
                        id={'emotion' + utils.EMOTION_LIKE}
                    />
                </Tooltip>
            </div>
            <div className="column">
                <Tooltip placement="top" title={t('Heart')}>
                    <HoverLottie
                        animationData={heart}
                        height={24}
                        width={24}
                        onClick={postEmotion}
                        id={'emotion' + utils.EMOTION_HEART}
                    />
                </Tooltip>
            </div>
            <div className="column">
                <Tooltip placement="top" title={t('Happy')}>
                    <HoverLottie
                        animationData={happy}
                        height={24}
                        width={24}
                        onClick={postEmotion}
                        id={'emotion' + utils.EMOTION_HAPPY}
                    />
                </Tooltip>
            </div>
            <div className="column">
                <Tooltip placement="top" title={t('Sad')}>
                    <HoverLottie
                        animationData={sad}
                        height={24}
                        width={24}
                        onClick={postEmotion}
                        id={'emotion' + utils.EMOTION_SAD}
                    />
                </Tooltip>
            </div>
        </div>
    );

    return (
        <>
            <span>
                <Space wrap>
                    <Popover
                        placement="bottom"
                        content={popupContent}
                        open={popupOpen}
                        onOpenChange={handleOpenChange}
                        trigger="click"
                    >
                        {/* 👍 */}
                        <img
                            src="/img/thumbs_up.svg"
                            width="18"
                            alt="Like"
                            style={{
                                paddingTop: 12,
                                cursor: 'pointer',
                                marginBottom: -2,
                            }}
                        />
                    </Popover>
                </Space>
            </span>
        </>
    );
}
