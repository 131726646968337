import axios from 'axios';

import utils from '../utils/utils.js';

export const fetchGatheringsByOwner = async (owner_id) => {
    const server_url = process.env.REACT_APP_ROOT_URL + `/gatherings_by_owner`;

    try {
        const response = await axios.post(
            server_url,
            {
                owner_id: owner_id,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            {
                withCredentials: true,
            }
        );

        const gatherings = response.data.gatherings;
        const orderedGatherings = utils.sortByName(gatherings);
        return orderedGatherings;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};

export const fetchAllGatheringsForParticipants = async (participantIds) => {
    const server_url =
        process.env.REACT_APP_ROOT_URL + `/gatherings_by_participants`;

    try {
        const response = await axios.post(server_url, {
            participant_ids: participantIds,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const gatherings = response.data.gatherings;
        const orderedGatherings = utils.sortByName(gatherings);
        return orderedGatherings;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
