import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(HttpBackend) // load translation using http -> see /public/locales
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        fallbackLng: 'en',
        debug: true,
        detection: {
            order: [
                'queryString',
                'cookie',
                'localStorage',
                'htmlTag',
                'navigator',
            ],
            caches: ['cookie'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json', // path where resources load from
        },
    });

export default i18n;
