import { Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../api/AuthContext';
import { createVenue } from '../api/createVenue.js';
import { deleteVenue } from '../api/deleteVenue.js';
import { fetchVenue } from '../api/fetchVenue.js';
import { fetchVenuesByOwner } from '../api/fetchVenues.js';
import { updateVenue } from '../api/updateVenue.js';
import { showToast, updateToast } from '../utils/toastUtils.js';

const { Option } = Select;

export default function Venue({ venue_id, setVenueId }) {
    const auth = useAuth();

    // Venues list for combo box.
    const [venues, setVenues] = useState(null);
    // Basic Venue properties.
    const [venue_name, setVenueName] = useState(null);
    const [venue_image_url, setVenueImageUrl] = useState(null);
    const [venue_location, setVenueLocation] = useState(null);
    // Custom Select properties.
    const [open, setOpen] = useState(false);
    // Flow control.
    const [isShowingEditVenueModal, setIsShowingEditVenueModal] =
        useState(false);
    const [isShowingDeleteVenueModal, setIsShowingDeleteVenueModal] =
        useState(false);

    useEffect(() => {
        getVenues(auth.currentUser.user_id);
    }, [auth.currentUser.user_id]);

    const toggleEditVenueModal = async (venue_id) => {
        if (venue_id !== '') {
            await getVenue(venue_id);
        }
        setIsShowingEditVenueModal(!isShowingEditVenueModal);
    };

    const toggleDeleteVenueModal = async (venue_id) => {
        if (venue_id !== '') {
            await getVenue(venue_id);
        }
        setIsShowingDeleteVenueModal(!isShowingDeleteVenueModal);
    };

    const getVenue = async (venue_id) => {
        try {
            const res = await fetchVenue(venue_id);
            setVenueName(res.name);
            setVenueImageUrl(res.image_url !== null ? res.image_url : '');
            setVenueLocation(res.location !== null ? res.location : '');
        } catch (err) {
            console.error(err);
        }
    };

    const getVenues = async (owner_id) => {
        try {
            const res = await fetchVenuesByOwner(owner_id);
            setVenues(res);
        } catch (err) {
            console.error(err);
        }
    };

    const commitVenueCreate = async () => {
        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdCreateVenue',
        });

        const venue = {
            owner_id: auth.currentUser.user_id,
            name: venue_name,
        };

        try {
            // Create the venue
            let response = await createVenue(venue);

            // Update the toast notification
            updateToast(toastId, auth.currentUser.email, {
                render: 'Venue created',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });

            // Refresh the list of venues and auto-select the new one
            await getVenues(auth.currentUser.user_id);
            setVenueId(response.venue_id); // Automatically select the newly created venue
        } catch (err) {
            console.error(err);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    const commitVenueChange = async () => {
        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdUpdateVenue',
        });

        try {
            await updateVenue(
                venue_id,
                auth.currentUser.user_id,
                venue_name,
                venue_image_url,
                venue_location
            );
            updateToast(toastId, auth.currentUser.email, {
                render: 'Venue updated',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });
            getVenues(auth.currentUser.user_id);
            toggleEditVenueModal(venue_id);
        } catch (err) {
            console.error(err);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    const commitVenueDelete = async () => {
        const toastId = showToast('One moment...', auth.currentUser.email, {
            toastId: 'toastIdDeleteVenue',
        });

        try {
            await deleteVenue(venue_id);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Venue deleted',
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });
            getVenues(auth.currentUser.user_id);
            venue_id = '';
            toggleDeleteVenueModal(venue_id);
            toggleEditVenueModal(venue_id);
        } catch (err) {
            console.error(err);
            updateToast(toastId, auth.currentUser.email, {
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    return (
        <>
            <label className="label">Venue</label>
            <div className="columns">
                <div className="column custom-dropdown-icon">
                    {/* The combo box showing all the venues for this user. */}
                    <Select
                        id="venue"
                        popupClassName="venueClass"
                        value={venue_id}
                        style={{ width: 300 }}
                        onChange={setVenueId}
                        size={'large'}
                        dropdownRender={(menu) => (
                            <div>
                                {menu}
                                <div className="columns">
                                    <div className="column is-two-thirds">
                                        <input
                                            className="input"
                                            id="inputNewVenueFromCombobox"
                                            onChange={(e) =>
                                                setVenueName(e.target.value)
                                            }
                                            placeholder="Enter new venue name"
                                            type="text"
                                        />
                                    </div>
                                    <div className="column">
                                        <button
                                            id="addNewVenueFromCombobox"
                                            className="button"
                                            disabled={!venue_name}
                                            onClick={commitVenueCreate}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    >
                        <Option value="">Select a venue</Option>
                        {venues &&
                            venues.map((venue) => (
                                <Option
                                    id={'option-' + venue.venue_id}
                                    key={venue.venue_id}
                                    value={venue.venue_id}
                                >
                                    {venue.name}
                                </Option>
                            ))}
                    </Select>
                    {venue_id && (
                        <div className="dropdown-edit-btn">
                            <img
                                id={'editVenue-' + venue_id}
                                src="/img/icon-edit.svg"
                                alt="Edit venue"
                                onClick={() => toggleEditVenueModal(venue_id)}
                            />
                        </div>
                    )}
                </div>
            </div>

            {/* The Edit Venue modal (edits the currently selected venue). */}
            {isShowingEditVenueModal && (
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">
                                Edit venue: {venue_name}
                            </p>
                            <button
                                id="closeEditVenue"
                                className="delete"
                                aria-label="close"
                                onClick={() => toggleEditVenueModal(venue_id)}
                            ></button>
                        </header>
                        <section className="modal-card-body">
                            <div className="field">
                                <input
                                    className="input"
                                    type="text"
                                    id="venue_name"
                                    placeholder="Enter venue name"
                                    value={venue_name}
                                    onChange={(e) =>
                                        setVenueName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="field">
                                <input
                                    className="input"
                                    type="text"
                                    id="venue_image_url"
                                    placeholder="Enter image url"
                                    value={venue_image_url}
                                    onChange={(e) =>
                                        setVenueImageUrl(e.target.value)
                                    }
                                />
                            </div>
                            <div className="field">
                                <input
                                    className="input"
                                    type="text"
                                    id="venue_location"
                                    placeholder="Enter venue"
                                    value={venue_location}
                                    onChange={(e) =>
                                        setVenueLocation(e.target.value)
                                    }
                                />
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                            <button
                                id="buttonConfirmEditVenue"
                                className="button is-success"
                                onClick={commitVenueChange}
                            >
                                Update
                            </button>
                            <button
                                id="buttonDeleteVenue"
                                className="button is-danger"
                                onClick={() => toggleDeleteVenueModal(venue_id)}
                            >
                                Delete
                            </button>
                            <button
                                id="cancelEditVenue"
                                className="button"
                                onClick={toggleEditVenueModal}
                            >
                                Cancel
                            </button>
                        </footer>
                    </div>
                </div>
            )}

            {/* The Delete Venue modal (confirms deleting currently selected venue). */}
            {isShowingDeleteVenueModal && (
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">
                                Delete Venue: {venue_name}?
                            </p>
                            <button
                                className="delete"
                                aria-label="close"
                                onClick={() =>
                                    setIsShowingDeleteVenueModal(false)
                                }
                            ></button>
                        </header>
                        <section className="modal-card-body">
                            Are you sure? This can not be undone!
                        </section>
                        <footer className="modal-card-foot">
                            <button
                                id="confirmDeleteVenue"
                                className="button is-danger"
                                onClick={() => commitVenueDelete(venue_id)}
                            >
                                Confirm delete
                            </button>
                            <button
                                id="cancelDeleteVenue"
                                className="button"
                                onClick={() =>
                                    setIsShowingDeleteVenueModal(false)
                                }
                            >
                                Cancel
                            </button>
                        </footer>
                    </div>
                </div>
            )}
        </>
    );
}
