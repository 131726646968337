import axios from 'axios';

import utils from '../utils/utils.js';

export const fetchSessionParticipants = async (session_id) => {
    var session_url =
        process.env.REACT_APP_ROOT_URL +
        `/attendance/?session_id=${session_id}`;

    try {
        const response = await axios.get(session_url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const attendances = response.data.attendances;
        const orderedParticipants = utils.sortByName(attendances);
        return orderedParticipants;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};

export const fetchAllParticipantsByOwner = async (owner_id) => {
    const session_url =
        process.env.REACT_APP_ROOT_URL + '/participants_by_owner/';

    try {
        const response = await axios.post(
            session_url,
            {
                owner_id: owner_id,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            {
                withCredentials: true,
            }
        );
        const participants = response.data.participants;
        const orderedParticipants = utils.sortByName(participants);
        return orderedParticipants;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};

export const fetchAllParticipantsByOwnerWithEvent = async (
    owner_id,
    event_id
) => {
    const session_url =
        process.env.REACT_APP_ROOT_URL + '/participants_by_owner_with_event/';

    try {
        const response = await axios.post(
            session_url,
            {
                owner_id: owner_id,
                event_id: event_id,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            {
                withCredentials: true,
            }
        );
        const participants = response.data.participants;
        const orderedParticipants = utils.sortByName(participants);
        return orderedParticipants;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};

export const fetchAllowedParticipants = async (event_id) => {
    const session_url =
        process.env.REACT_APP_ROOT_URL +
        `/allowed_participants/?event_id=${event_id}`;

    try {
        const response = await axios.get(session_url, {
            event_id: event_id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const participants = response.data.participants;
        const orderedParticipants = utils.sortByName(participants);
        return orderedParticipants;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
