import { toast } from 'react-toastify';

import utils from '../utils/utils';

const maybeAddTestUserOverrides = (authUserEmail, options) => {
    if (typeof authUserEmail !== 'string') {
        throw new Error('Must supply some authUserEmail (blank string ok)');
    }

    if (authUserEmail === '') return options;

    // Make toasts faster for test runs and support toasts that don't stick
    // around forever if we switch away from the puppeteer window.
    if (utils.isTestUser(authUserEmail)) {
        options['autoClose'] = 100; // Milliseconds.
        options['pauseOnFocusLoss'] = false;
    }

    return options;
};

export const simpleToast = (message) => {
    return toast(message);
};

export const showToast = (message, authUserEmail, options = {}) => {
    return toast.loading(
        message,
        maybeAddTestUserOverrides(authUserEmail, options)
    );
};

export const updateToast = (toastId, authUserEmail, options = {}) => {
    toast.update(toastId, maybeAddTestUserOverrides(authUserEmail, options));
};
