import { useTranslation } from 'react-i18next';

export const Sponsors = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="sponsor-title">{t('Sponsors')} ♥</div>
            <div className="sponsor-logos">
                <div className="sponsor">
                    <a href="https://arev.is">
                        <img src="/img/sponsor-arev.png" alt="Arev" />
                    </a>
                </div>
                <div className="sponsor">
                    <a href="https://kemia.is">
                        <img
                            src="/img/sponsor-kemia.png"
                            alt="Kemía"
                            style={{ maxWidth: '70px' }}
                        />
                    </a>
                </div>
                <div className="sponsor">
                    <a href="https://livefromiceland.is">
                        <img
                            src="/img/sponsor-livefromiceland.svg"
                            alt="Guinnes - Ölgerðin"
                        />
                    </a>
                </div>
                <div className="sponsor" style={{ padding: 0 }}>
                    <a href="https://netheimur.is">
                        <img
                            style={{ width: '84%' }}
                            src="/img/sponsor-netheimur.svg"
                            alt="Netheimur"
                        />
                    </a>
                </div>
                <div className="sponsor">
                    <a href="https://betsson.com">
                        <img src="/img/sponsor-betsson.svg" alt="Betsson" />
                    </a>
                </div>
                <div className="sponsor">
                    <a href="https://olgerdin.is">
                        <img
                            src="/img/sponsor-guinnes.svg"
                            alt="Guinnes - Ölgerðin"
                            style={{ maxWidth: '90px' }}
                        />
                    </a>
                </div>
            </div>
        </>
    );
};
