import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth } from '../api/AuthContext';
import { showToast, updateToast } from '../utils/toastUtils.js';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const auth = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const signupStatus = searchParams.get('signup');

    useEffect(() => {
        if (auth.currentUser) {
            navigate('/admin');
        }
    }, [auth.currentUser, navigate]);

    const closeModal = () => {
        navigate('/login');
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Implement actual login logic, possibly using an API call

        const toastId = showToast('One moment...', email, {
            toastId: 'toastIdLogin',
        });

        try {
            const res = await auth.signIn(email, password);

            if (res.status === 200) {
                updateToast(toastId, email, {
                    render: 'Login succeeded',
                    type: 'success',
                    isLoading: false,
                    autoClose: 1500,
                });
                // We navigate to the Welcome page (the first run wizard). The
                // wizard page may determine it doesn't need to run and will
                // then navigate to the dashboard.
                navigate('/admin/welcome/');
            } else {
                updateToast(toastId, email, {
                    render: res.data.message,
                    type: 'error',
                    autoClose: 2000,
                    isLoading: false,
                });
            }
        } catch (err) {
            console.error('login failed', err);
            updateToast(toastId, email, {
                render: err.message,
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    return (
        <div className="App">
            <div>
                {signupStatus === 'success' && (
                    <div className="lightbox">
                        <div className="modal-custom">
                            <h1>Welcome aboard!</h1>
                            <img src="/img/Success.png" alt="Signup success" />
                            <p>
                                We've just sent a quick email to your inbox.
                                <strong className="blue">
                                    Click the link inside to confirm your email
                                    and get started.
                                </strong>
                                It’s the key to unlocking all the cool stuff we
                                have in store for you.
                            </p>
                            <p>
                                If the email does not arrive in the next 30
                                seconds you can click{' '}
                                <Link to="/password-recovery" className="blue">
                                    here to get a new verification email
                                </Link>
                            </p>
                            <br />
                            <br />
                            <button className="button" onClick={closeModal}>
                                Close
                            </button>
                        </div>
                    </div>
                )}

                <div className="signuplogin">
                    <section className="form-section">
                        <div className="form-wrapper">
                            <h1 className="title">Login</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>
                                        <span className="sr-only">
                                            Email address
                                        </span>
                                        <input
                                            id="email"
                                            type="email"
                                            placeholder="Email"
                                            className="form-input"
                                            required
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            autoComplete="username"
                                        />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label>
                                        <span className="sr-only">
                                            Password
                                        </span>
                                        <input
                                            id="password"
                                            type="password"
                                            placeholder="Password"
                                            className="form-input"
                                            required
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            autoComplete="current-password"
                                        />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <input
                                        id="submit"
                                        type="submit"
                                        value="Submit"
                                        className="form-submit"
                                    />
                                </div>

                                <footer className="form-footer">
                                    <div>
                                        <a
                                            href="/password-recovery"
                                            className="form-link"
                                        >
                                            Forgot password?
                                        </a>
                                    </div>
                                    <div>
                                        Dont have an account?
                                        <a href="/signup" className="form-link">
                                            {' '}
                                            Sign up
                                        </a>
                                    </div>
                                </footer>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
            <div className="footer"></div>
        </div>
    );
}

export default Login;
