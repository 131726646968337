import axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';

import utils from '../utils/utils.js';

moment().locale('is');

export const updateEvent = async (
    event_id,
    gathering_id,
    location,
    name,
    short_description,
    long_description,
    is_single_event,
    weekday,
    time,
    single_date,
    min_participants,
    max_participants,
    owner_id,
    notifications,
    notificationType
    //active,
) => {
    const session_url = process.env.REACT_APP_ROOT_URL + '/event';
    if (single_date === undefined) {
        single_date = null;
    }
    if (weekday === undefined) {
        weekday = 0;
    }
    try {
        const response = await axios.patch(
            session_url,
            {
                event_id: event_id,
                owner_id: owner_id,
                gathering_id: gathering_id,
                name: name,
                short_description: short_description,
                long_description: long_description,
                type: is_single_event
                    ? utils.SINGLE_EVENT
                    : utils.REPEATING_EVENT,
                weekday: is_single_event ? undefined : weekday,
                time_of_day: is_single_event ? undefined : time.format('HH:mm'),
                single_date: is_single_event ? single_date : undefined,
                active: '1',
                venue_id: location,
                min_participants: min_participants,
                max_participants: max_participants,
                image_url: '',
                notifications: notifications,
                notificationType: notificationType,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
            {
                withCredentials: true,
            }
        );
        return response.data;
    } catch (err) {
        console.log(err.message);
        console.log(err.response);
        throw err;
    }
};
