import { Tooltip } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

import utils from '../utils/utils.js';

export default function EmoteIcon({
    emoteIconType,
    emotionsList,
    participants,
}) {
    const [emotionCount, setEmotionCount] = useState(0);
    const [
        pariticpantsWhoShowedThisEmotion,
        setParticpantsWhoShowedThisEmotion,
    ] = useState(null);

    useEffect(() => {
        let count = 0;
        let tooltipText = '';
        emotionsList.forEach((emotion) => {
            if (emotion.emotion === emoteIconType) {
                count++;
                let participant = participants.find(
                    (participant) =>
                        participant.participant_id === emotion.participant_id
                );
                if (participant) {
                    tooltipText += participant.name + '\n';
                }
            }
        });
        if (tooltipText.length > 0) {
            let prefix = '';
            switch (emoteIconType) {
                case utils.EMOTION_LIKE:
                    prefix = t('Like') + ':\n';
                    break;
                case utils.EMOTION_HEART:
                    prefix = t('Heart') + ':\n';
                    break;
                case utils.EMOTION_HAPPY:
                    prefix = t('Happy') + ':\n';
                    break;
                case utils.EMOTION_SAD:
                    prefix = t('Sad') + ':\n';
                    break;
                default:
                    prefix = 'invalid prefix\n';
            }
            if (emoteIconType === utils.EMOTION_LIKE) {
            }
            setParticpantsWhoShowedThisEmotion(prefix + tooltipText);
        } else {
            setParticpantsWhoShowedThisEmotion('~~None~~');
        }
        setEmotionCount(count);
    }, [emotionsList]);

    return (
        <>
            <Tooltip
                overlayStyle={{ whiteSpace: 'pre-line' }}
                title={pariticpantsWhoShowedThisEmotion}
            >
                {emoteIconType === utils.EMOTION_LIKE && emotionCount > 0 && (
                    <span>
                        👍
                        {/*  <img
                            src="/img/thumbs_up.png"
                            width="14"
                            alt="Like"
                            style={{ paddingTop: 9 }}
                        /> */}
                    </span>
                )}
                {emoteIconType === utils.EMOTION_HEART && emotionCount > 0 && (
                    <span>
                        <img
                            src="/img/heart.svg"
                            width="14"
                            alt="Heart"
                            style={{ paddingTop: 9 }}
                        />
                    </span>
                )}
                {emoteIconType === utils.EMOTION_HAPPY && emotionCount > 0 && (
                    <span>
                        😀
                        {/* <img
                            src="/img/happy.png"
                            width="14"
                            alt="Heppy"
                            style={{ paddingTop: 9 }}
                        /> */}
                    </span>
                )}
                {emoteIconType === utils.EMOTION_SAD && emotionCount > 0 && (
                    <span>
                        😞
                        {/*  <img
                            src="/img/sad.png"
                            width="14"
                            alt="Sad"
                            style={{ paddingTop: 9 }}
                        /> */}
                    </span>
                )}
            </Tooltip>
        </>
    );
}
