import axios from 'axios';
import moment from 'moment';
import 'moment/locale/is';

moment().locale('is');

export const fetchPastSessions = async (owner_id) => {
    var session_url =
        process.env.REACT_APP_ROOT_URL + '/past_sessions?owner_id=' + owner_id;

    try {
        const response = await axios.get(session_url, {
            owner_id: owner_id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        let sessions = response.data.pastSessions;

        sessions.sort(function (a, b) {
            let dateA = moment(a.timestamp);
            let dateB = moment(b.timestamp);
            return dateA > dateB ? -1 : dateA === dateB ? 0 : 1;
        });

        return sessions;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
