import { Select } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment/locale/is';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../api/AuthContext';
import { fetchAllEventsByOwner } from '../api/fetchAllEvents.js';
import { queryAttendance } from '../api/queryAttendance.js';
import { ensureNonNegativeAndForwardTo } from '../utils/utils.js';

moment().locale('is');

let comboEvents = [];

function Statistics() {
    const auth = useAuth();

    const [selectedItems, setSelectedItems] = useState([]);
    const [dateRange, setDateRange] = useState(null);
    const [num_participants, setNumParticipants] = useState(10);
    const [queryResults, setQueryResults] = useState([]);

    const filteredOptions = comboEvents.filter((item) => {
        return !selectedItems.includes(item);
    });

    const fetchEvents = async (owner_id) => {
        try {
            const result = await fetchAllEventsByOwner(owner_id);

            comboEvents = [];
            let selected = [];
            result.forEach((item) => {
                comboEvents.push({ id: item.event_id, name: item.name });
                selected.push(item.event_id);
            });
            setSelectedItems(selected);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchEvents(auth.currentUser.user_id);
        setDateRange('thisMonth');
    }, [auth.currentUser.user_id]);

    const queryAttendanceStats = async () => {
        let startDate;
        let endDate;

        const dateFormat = 'YYYY-MM-DD HH:mm:ss';
        const now = new dayjs();

        switch (dateRange) {
            case 'thisMonth':
                startDate = now.startOf('month').format(dateFormat);
                endDate = now.endOf('month').format(dateFormat);
                break;
            case 'thisYear':
                startDate = now.startOf('year').format(dateFormat);
                endDate = now.endOf('year').format(dateFormat);
                break;
            case 'lastMonth':
                startDate = now
                    .subtract(1, 'month')
                    .startOf('month')
                    .format(dateFormat);
                endDate = now
                    .subtract(1, 'month')
                    .endOf('month')
                    .format(dateFormat);
                break;
            case 'lastYear':
                startDate = now
                    .subtract(1, 'year')
                    .startOf('year')
                    .format(dateFormat);
                endDate = now
                    .subtract(1, 'year')
                    .endOf('year')
                    .format(dateFormat);
                break;
            default:
                startDate = null;
                endDate = null;
        }

        const response = await queryAttendance(
            startDate,
            endDate,
            selectedItems,
            num_participants
        );

        let results = [];
        response.forEach((item) => {
            results.push(
                item.attendanceCount.toString().padStart(3, ' ') +
                    ' ' +
                    item.name +
                    (item.nickname !== '' ? ' / ' + item.nickname : '')
            );
        });
        setQueryResults(results);
    };

    const listItems = queryResults.map((participant, i) => (
        <li key={i}>
            <p>
                <b>{participant}</b>
            </p>
        </li>
    ));

    return (
        <>
            <h1>Statistics</h1> <br />
            <div className="block">
                <h2>Attendance report</h2>
                <label className="label">Selected events:</label>
                <Select
                    mode="multiple"
                    placeholder="Select events to continue"
                    value={selectedItems}
                    onChange={setSelectedItems}
                    style={{ width: '100%' }}
                    options={filteredOptions.map((item) => ({
                        value: item.id,
                        label: item.name,
                    }))}
                />
                <br />
                <br />
                <label className="label">Date range:</label>
                <Select
                    style={{ width: 120 }}
                    id="dateRange"
                    popupClassName="repeatDayClass"
                    value={dateRange}
                    onChange={setDateRange}
                    options={[
                        {
                            id: 'thisMonth',
                            value: 'thisMonth',
                            label: 'This month',
                        },
                        {
                            id: 'thisYear',
                            value: 'thisYear',
                            label: 'This year',
                        },
                        {
                            id: 'lastMonth',
                            value: 'lastMonth',
                            label: 'Last month',
                        },
                        {
                            id: 'lastYear',
                            value: 'lastYear',
                            label: 'Last year',
                        },
                    ]}
                />
                <br />
                <br />
                <label className="label">Max rows to show:</label>
                <input
                    className="input"
                    type="number"
                    id="num_participants"
                    value={num_participants}
                    onChange={(e) =>
                        ensureNonNegativeAndForwardTo(
                            e.target.value,
                            setNumParticipants
                        )
                    }
                    pattern="\d*"
                />
                <br />
                <br />
                <button
                    id="queryAttendanceButton"
                    className="button"
                    disabled={selectedItems.length === 0}
                    onClick={() => queryAttendanceStats()}
                >
                    Query
                </button>
                <br />
                <br />
                <ul>{listItems}</ul>
            </div>
        </>
    );
}

export default Statistics;
