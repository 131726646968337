import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import moment from 'moment';
import 'moment/locale/is';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../api/AuthContext';
import { createGathering } from '../api/createGathering.js';
import { createVenue } from '../api/createVenue.js';
import { fetchAllEventsByOwner } from '../api/fetchAllEvents.js';
import { fetchGatheringsByOwner } from '../api/fetchGatherings.js';
import { fetchVenuesByOwner } from '../api/fetchVenues.js';
import { showToast } from '../utils/toastUtils.js';
import utils from '../utils/utils.js';

moment().locale('is');

function Welcome() {
    const [gathering_name, setGatheringName] = useState(null);
    const [venue_name, setVenueName] = useState(null);

    const auth = useAuth();
    const navigate = useNavigate();

    const navigateToAdminPage = () => {
        navigate('/admin');
    };

    const getData = async (owner_id) => {
        try {
            // If this user has already created a gathering, venue and/or event,
            // then we don't need to go through this step.
            const gatherings = await fetchGatheringsByOwner(owner_id);
            if (gatherings.length > 0) navigateToAdminPage();
            const venues = await fetchVenuesByOwner(owner_id);
            if (venues.length > 0) navigateToAdminPage();
            const events = await fetchAllEventsByOwner(owner_id);
            if (events.length > 0) navigateToAdminPage();
        } catch (err) {
            console.error(err);
        }
    };

    const confirmNewEntries = async () => {
        try {
            let response = await createGathering({
                owner_id: auth.currentUser.user_id,
                name: gathering_name,
            });
            const gatheringId = response.data.gathering_id;

            response = await createVenue({
                owner_id: auth.currentUser.user_id,
                name: venue_name,
            });
            const venueId = response.data.venue_id;

            // Since two objects are created at the same time, we conbime the
            // uuids to make it easier for the tests to pick up.
            utils.saveLastCreatedUuid(gatheringId + ' ' + venueId);

            navigate('/admin/participants/?welcome');
        } catch (err) {
            console.error(err);
            showToast('Something went wrong', auth.currentUser.email, {
                toastId: 'toastIdWelcomePageError',
                render: 'Something went wrong',
                type: 'error',
                autoClose: 2000,
                isLoading: false,
            });
        }
    };

    useEffect(() => {
        getData(auth.currentUser.user_id);
    }, [auth.currentUser.user_id]);

    const tooltipOverview =
        "This form will help you get up and running quicker. You don't have " +
        'to fill this out now, but it will help you jumpstart the process ' +
        'once you are ready to create your first event. Also, the ' +
        "information you enter below can be changed later, so don't worry " +
        'about having to get it perfect on the first try. And you can of ' +
        'course create more groups and venues later. Hover over the rest of ' +
        'the information signs for more hints.';
    const tooltipGroupName =
        'Groups help you organize the relationship between your events and ' +
        'your pariticpants. A good group name often consist of both the ' +
        "'Who' and the 'What' -- as in: If you are organizing football events " +
        "for your fellow employees at Acme Company, we'd suggest 'Acme " +
        "Football'.";
    const tooltipVenueName =
        'A venue is where you want your group to meet. Some examples: for a ' +
        'social event, you could put down the name of a nearby park. If you ' +
        'are organizing a practice for the local choir you could write the ' +
        'name of the town church where you get together, and for a football ' +
        'practice write the name of the pitch where you practice.';

    return (
        <div className="App">
            <div>
                <div className="welcome">
                    <section className="form-section">
                        <div className="form-wrapper">
                            <h1 id="pageWelcome">Welcome!</h1> <br />
                            <div className="block">
                                Couple of questions before we begin...{' '}
                                <Tooltip title={tooltipOverview}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                                <br />
                                <br />
                                <div className="field">
                                    <label className="label">
                                        What is your group name?{' '}
                                        <Tooltip title={tooltipGroupName}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type="text"
                                            id="gathering_name"
                                            placeholder="Enter group name"
                                            onChange={(e) =>
                                                setGatheringName(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">
                                        Where do you normally get together for
                                        events?{' '}
                                        <Tooltip title={tooltipVenueName}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type="text"
                                            id="venue_name"
                                            onChange={(e) =>
                                                setVenueName(e.target.value)
                                            }
                                            placeholder="Enter stadium/venue name"
                                        />
                                    </div>
                                </div>
                                <button
                                    id="buttonNext"
                                    className="button is-success"
                                    disabled={!gathering_name || !venue_name}
                                    onClick={() => confirmNewEntries()}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="footer"></div>
        </div>
    );
}

export default Welcome;
